export const getContactPageProps = ({
  strapiContactUs: {
    seo,
    formValues,
    btnArrow,
    titlePartOne,
    titlePartTwo,
    subtitle,
  },
}) => {
  return {
    seo,
    formValues,
    btnArrow,
    titlePartOne,
    titlePartTwo,
    subtitle,
  };
};
