import React from "react";
import { graphql } from "gatsby";
import ContactPage from "components/contactPage";
import { getContactPageProps } from "helpers/getContactPageProps";
import Layout from "components/layout";

const Contact = ({ data }) => {
  return (
    <Layout>
      <ContactPage {...getContactPageProps(data)} />
    </Layout>
  );
};

export const query = graphql`
  query ContactPageData {
    strapiContactUs {
      seo {
        metaTitle
        metaDescription
        shareImage {
          alternativeText
          localFile {
            url
          }
        }
      }
      titlePartOne
      titlePartTwo
      subtitle
      btnArrow {
        alternativeText
        localFile {
          url
        }
      }
      formValues {
        serviceTitle
        addLinkTitle
        budgetTitle
        sendFormBtn
        sendEmailBtn
        inputFields {
          email
          fullName
          phone
          message
        }
        addLink {
          add
          upload
        }
        budget {
          name
          order
        }
        services {
          name
          order
        }
      }
    }
  }
`;

export default Contact;
