import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Seo from "components/seo";
import FormValues from "components/formValues";
import * as styles from "./contactPage.module.scss";

const ContactPage = ({
  seo,
  formValues,
  btnArrow,
  titlePartOne,
  titlePartTwo,
  subtitle,
}) => {
  return (
    <>
      <Seo {...seo} />
      <div className={styles.titleWrapper}>
        <div className={styles.titlePart}>
          <div className={`${styles.title}`}>
            <p>
              {titlePartOne} <span>{titlePartTwo}</span>
            </p>
          </div>
          <p className={styles.subtitle}>{subtitle}</p>
        </div>
      </div>
      <FormValues {...formValues} btnArrow={btnArrow} />
    </>
  );
};

export default ContactPage;
