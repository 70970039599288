import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import { validateRequest } from "helpers/validateFormToApply";
import { getStaticImgProps } from "helpers/getImgProps";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";
import cross from "images/cross.png";
import * as styles from "./formValues.module.scss";

const FormValues = ({
  sendEmailBtn,
  sendFormBtn,
  serviceTitle,
  budgetTitle,
  addLinkTitle,
  btnArrow,
  inputFields,
  budget,
  services,
  addLink,
}) => {
  const [isVerified, setIsVerified] = useState(false);
  const { email, fullName, phone, message } = inputFields;
  const [uploadedFile, setUploadedFile] = useState("");
  const [recaptchaWarning, setRecaptchaWarning] = useState("");
  const { add, upload } = addLink;

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    setUploadedFile(file);
  };
  const handleFileDelete = () => {
    setUploadedFile(null);
  };
  const handleReCapthcaOnChange = () => {
    setIsVerified(true);
  };
  const submitForm = async (formValues) => {
    const url = `${process.env.GATSBY_STRAPI_API_URL}/api/contact-forms`;
    const data = { data: formValues };

    if (isVerified) {
      try {
        if (isVerified) {
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to create data");
              }
              return response.json();
            })
            .then(() => {
              navigate("/thank-you");
            })
            .catch((error) => {
              console.error("Error creating data:", error);
            });
        }
      } catch (error) {
        console.error("Form submission failed");
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
      message: "",
      link: "",
      file: "",
      service: services[0].name,
      budget: budget[0].name,
    },
    validate: validateRequest,
    onSubmit: (values) => {
      if (!isVerified) {
        return setRecaptchaWarning(
          "Please complete the reCAPTCHA verification."
        );
      }
      submitForm(values);
    },
  });
  return (
    <div>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <div
          className={`${styles.inputFields} d-flex flex-column flex-md-row justify-content-md-between align-items-center`}
        >
          <input
            id="fullName"
            name="fullName"
            type="text"
            placeholder={fullName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fullName}
            className={
              formik.errors.fullName
                ? `${styles.invalid} ${styles.name}`
                : styles.name
            }
          />
          <input
            id="email"
            name="email"
            type="email"
            placeholder={email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className={
              formik.errors.email
                ? `${styles.invalid} ${styles.email}`
                : styles.email
            }
          />
          <input
            id="phone"
            name="phone"
            type="text"
            placeholder={phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            className={
              formik.errors.phone
                ? `${styles.invalid} ${styles.phone}`
                : styles.phone
            }
          />
          <input
            id="message"
            name="message"
            type="textarea"
            placeholder={message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
            className={
              formik.errors.message
                ? `${styles.invalid} ${styles.message}`
                : styles.message
            }
          />
        </div>
        <p className={`${styles.formSubtitle} mv-20`}>{serviceTitle}</p>
        <div className={styles.formLabels}>
          {sortArrayOfObjects(
            services.map(({ name }, i) => {
              return (
                <div
                  className={`${styles.singleCheckbox} d-flex justify-content-start align-items-center`}
                  key={i}
                >
                  <label
                    className={`${styles.container} d-flex justify-content-start align-items-center`}
                  >
                    <input
                      type="radio"
                      name="services"
                      onChange={formik.handleChange}
                      value={formik.values.service}
                      className="mr-18"
                      defaultChecked={i === 0 && true}
                    />
                    <span className={styles.checkmark}></span>
                    {name}
                  </label>
                </div>
              );
            })
          )}
        </div>
        <p className={`${styles.formSubtitle} mv-20`}>{budgetTitle}</p>
        <div className={styles.formLabels}>
          {sortArrayOfObjects(
            budget.map(({ name }, i) => {
              return (
                <div
                  className={`${styles.singleCheckbox} d-flex justify-content-start align-items-center`}
                  key={i}
                >
                  <label
                    className={`${styles.container} d-flex justify-content-start align-items-center`}
                  >
                    <input
                      type="radio"
                      name="budget"
                      onChange={formik.handleChange}
                      value={formik.values.budget}
                      className="mr-18"
                      defaultChecked={i === 0 && true}
                    />
                    <span className={styles.checkmark}></span>
                    {name}
                  </label>
                </div>
              );
            })
          )}
        </div>
        <p className={`${styles.formSubtitle} mv-20`}>{addLinkTitle}</p>
        <div
          className={`${styles.formLinkUpload} d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mb-40`}
        >
          <input
            name="link"
            type="url"
            placeholder={add}
            className={styles.link}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.link}
          />
          <input
            type="file"
            name="file"
            className={styles.file}
            onBlur={formik.handleBlur}
            value={formik.values.file}
            onChange={handleFileUpload}
          />
          {uploadedFile && (
            <div className={styles.showUploadInfo}>
              <p className={styles.uploaded}>
                File uploaded: {uploadedFile.name}
              </p>
              <div className={styles.deleteUpload}>
                <img src={cross} alt="cross" onClick={handleFileDelete} />
              </div>
            </div>
          )}
        </div>

        <div className={styles.recaptcha}>
          <ReCAPTCHA
            sitekey="6LfFrUsmAAAAAMEXJ-Nm9eHFOEEA44zxDohPLoqw"
            onChange={handleReCapthcaOnChange}
          />
          {recaptchaWarning && !isVerified && (
            <p className={styles.warning}>{recaptchaWarning}</p>
          )}
        </div>
        <button
          type="submit"
          className={`${styles.sendRequest} pa-18 d-flex justify-content-center align-items-center mb-20`}
        >
          {sendFormBtn}
          <img {...getStaticImgProps(btnArrow)} />
        </button>
      </form>
      <p
        className={`${styles.sendEmail} mb-40 d-flex justify-content-center align-items-center`}
      >
        <a href="mailto:  hello@preezma.com">{sendEmailBtn}</a>
      </p>
    </div>
  );
};

export default FormValues;
